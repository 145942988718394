<template>
  <div class="app-container">
    <el-dialog title="" :visible.sync="dialogNPAView" width="70%">
      <div v-if="document">
        <NPA :document="document" />
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="2500"
            :filename="`NPA - ${document.employee_details.name}`"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="legal"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
        >
          <section slot="pdf-content">
            <NPA :document="document" />
          </section>
        </vue-html2pdf>
      </div>
      <div  style="margin-left: 2rem;" class="demo-input-suffix">
        <el-input  v-if="isComplete === '2'" style="width:26%" type="textarea" placeholder="Please put remarks" v-model="hr_is_email_remark" size="medium"></el-input>
      </div>
      <span slot="footer" class="dialog-footer-complete">
        <el-tooltip :content="'Switch value: ' + value" placement="top">
          <el-switch  v-if="isComplete === '2'" v-model="value" active-value= 1 inactive-value= 0 active-text="Include to Email"></el-switch>
        </el-tooltip>
        <el-button style="margin-left: 1rem;" v-if="isComplete === '2'" type="success" @click="updateCompletedDocument()">Complete</el-button>
        <el-button style="margin-left: 40rem;" type="primary" @click="generateReport">Download</el-button>
        <el-button @click="dialogNPAView = false">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Approvers" :visible.sync="dialogApproverVisible">
      <el-table :data="approvers">
        <el-table-column prop="hierarchy_no" width="50"></el-table-column>
        <el-table-column prop="boss_em_id_name" label="NAME">
          <template slot-scope="props">
            <div v-if="props.row.boss_em_id === $store.getters.user.employeeId"><b>{{props.row.boss_em_id_name}} (Me)</b></div>
            <div v-else>{{props.row.boss_em_id_name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="a_id_name" label="STATUS">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.file_status)">{{ props.row.file_status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="a_date_action" label="DATE ACTIONED">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span v-if="scope.row.a_date_action" style="margin-left: 10px">{{ formatDate(scope.row.a_date_action) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">DOCUMENT APPROVED DASHBOARD</h3>
        <div style="margin-left: 40rem;">
          <el-select v-model="isAcknowledged" placeholder="Status">
            <el-option label="Acknowledged" :value="1"></el-option>
            <el-option label="Not Acknowledged" :value="0"></el-option>
          </el-select>
        </div>
        <el-select v-model="isCompleted" placeholder="Status">
          <el-option label="Completed" :value="1"></el-option>
          <el-option label="Not Complete" :value="0"></el-option>
        </el-select>
        <div style="margin-left: 1rem;">
          <el-button  @click="SendEmail()">Send Email</el-button>
        </div>
      </div>
      <div class="controls">
      <div class="control">
        <p>Items per page: </p>
        <el-select v-model="items">
          <el-option :label="5" :value="5"></el-option>
          <el-option :label="10" :value="10"></el-option>
          <el-option :label="25" :value="25"></el-option>
          <el-option :label="50" :value="50"></el-option>
          <el-option :label="100" :value="100"></el-option>
        </el-select>
      </div>
      <div class="control">
        <el-button-group>
          <el-button type="primary" icon="el-icon-arrow-left" @click="prevPage()" :disabled="!hasPreviousPage">Previous Page</el-button>
          <el-button type="primary" @click="nextPage()" :disabled="!hasNextPage">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
        </el-button-group>
      </div>
    </div>
      <el-table :data="data" border class="hidden-sm-and-down">
        <el-table-column label="EMPLOYEE" prop="em_id_name"></el-table-column>
        <el-table-column label="DESCRIPTION" prop="npa_bg4_id_name"></el-table-column>
        <el-table-column label="DATE HIRED" prop="datehired">
          <template slot-scope="props">
            <p>{{ formatDate(props.row.datehired) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" prop="mss_a_id_name">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.mss_a_id_name)">{{ props.row.mss_a_id_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" fixed="right" align="left" width="180">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="View Document" placement="top-start">
              <el-button size="mini" type="danger" @click="view(scope.row)"><i class="el-icon-search"></i></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { format } from 'date-fns'
import NPA from '@/components/Documents/NPA.vue'

export default {
  components: {
    NPA
  },
  data () {
    return {
      dialogView: false,
      dialogNPAView: false,
      isComplete: 0,
      isAcknowledged: 0,
      value: 1,
      isCompleted: 0,
      items: 5,
      page: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      dialogApproverVisible: false,
      hed_id: 0,
      hr_is_email_remark: null,
      approvers: [],
      npa: null,
      data: []
    }
  },
  watch: {
    isAcknowledged () {
      this.getFiles()
    },
    isCompleted () {
      this.getFiles()
    },
    items () {
      this.getFiles()
    }
  },
  mounted () {
    this.getFiles()
  },
  methods: {
    getFiles () {
      this.$http
        .get(`hr.approved-document-list/${this.isAcknowledged}/${this.isCompleted}/${this.items}?page=${this.page}`)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body.data : []
          this.hasNextPage = res.data.StatusCode ? !!res.data.body.next_page_url : false
          this.hasPreviousPage = res.data.StatusCode ? !!res.data.body.prev_page_url : false
        })
    },
    nextPage () {
      this.page++
      this.getFiles()
    },
    prevPage () {
      this.page--
      this.getFiles()
    },
    updateCompletedDocument () {
      this.$confirm('Are you sure you want to complete this document?', 'info', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          const form = {
            id: this.hed_id,
            hr_is_email: this.value,
            hr_is_email_remark: this.hr_is_email_remark
          }
          this.$http
            .post('hr.document-update-completed', form)
            .then(() => {
              this.$message({
                type: 'success',
                message: 'Document Completed'
              })
              this.dialogNPAView = false
              this.getFiles()
            })
        })
    },
    view (data) {
      this.isComplete = data.mss_a_id
      this.hed_id = data.id
      this.bar_id = data.bar_id
      this.em_id = data.em_id
      this.$http
        .get(`hr.document-form-data/${data.id}/${data.bar_id}/${data.em_id}`)
        .then(res => {
          this.document = res.data.body
          if (this.document.bar_id === 1) {
            this.dialogNPAView = true
          } else if (this.document.bar_id === 2) {
            this.dialogMPPView = true
          }
        })
    },
    getDocumentStatus (id) {
      this.$http
        .get(`hr.document-approver-list/${id}`)
        .then(res => {
          this.approvers = res.data.body
          this.dialogApproverVisible = true
        })
    },
    getStatusType (type) {
      if (type === 'Waiting' || type === 'Hold' || type === 'For Revision') {
        return 'primary'
      } else if (type === 'Completed' || type === 'For Signature' || type === 'Approved') {
        return 'success'
      } else {
        return 'danger'
      }
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    formatDate (date, dateFormat = 'iii, dd MMM yyyy') {
      return format(new Date(date), dateFormat)
    },
    SendEmail () {
      this.$http
        .post('hr.document-emailrecipient')
    }
  }
}
</script>

<style lang="scss" scoped>
  .dialog-footer-complete {
    display: flex;
    align-items: center;
    margin-left: 2rem;
  }
  .dialog-footer {
    display: flex;
    align-items: left;
  }
</style>
